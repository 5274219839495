@media (max-width: 860px) {
    .header {
        display: grid;
        align-items: center;
        gap: .5rem;
        grid-template-columns: 36px 1fr 36px;
    }

    .icon {
        min-width: fit-content;
        min-height: fit-content;
        padding: .5rem;
        border-radius: .25rem;
        height: 30px;
    }

    .icon > svg {
        width: 1rem;
        height: 1rem;
        /*transform: rotate(180deg);*/
    }

    .drawer {
        width: 100%;
    }

    .profile {
        padding: 1rem;
        display: grid;
        justify-items: center;
        background: var(--border-disabled);
        margin: 2rem 1rem 1rem;
        border-radius: 1rem;
        /*padding: 0 1.5rem;*/
    }

    .profile > img {
        width: 64px;
        height: 64px;
        border-radius: 8px;
    }
    .menu {
        padding: 16px;
    }
    .menu .header {
        grid-template-columns: 0fr 1fr 0fr;
        width: 100%;
        margin: 1rem 0;
        padding: 0 1rem;
    }
    .popoverAudio {
        display: grid;
        width: 230px;
        padding: 0 1rem;
        max-height: 300px;
        overflow-y: scroll;
        border: 1px solid var(--border-disabled);
        border-radius: 24px;
    }
    .popoverAudio::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }
    .popoverAudio:hover::-webkit-scrollbar-thumb {
        background-color: transparent; /* No color change on hover */
        border-radius: 16px;
    }

    .popoverAudio > div {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, .5);
    }
    .popoverAudio > div > svg {
        margin-left: auto;
    }
    .popoverAudio > div:last-child {
        border-bottom: none;
    }
    .popoverAudio h5 {
        color: var(--white);
        transition: ease-in-out 4ms;

    }

    .popoverAudio h5:hover , .active h5{
        opacity: 1;
        transition: ease-in-out 4ms;
    }
    .popoverAudio .edit > div {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .popoverAudio .edit > div > svg{
        margin-left: 0;
    }
    .profile_image, .popoverAudio > div > img {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background: var(--border-disabled);

    }

}
