.box {
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 48px 50px 48px 32px;
    position: fixed;
    top: 12%;
}

.popoverAudio {
    display: grid;
    width: 230px;
    padding: 0 1rem;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid var(--border-disabled);
    border-radius: 24px;
}
.popoverAudio::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}
.popoverAudio:hover::-webkit-scrollbar-thumb {
    background-color: transparent; /* No color change on hover */
    border-radius: 16px;
}

.popoverAudio > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}
.popoverAudio > div > svg {
    margin-left: auto;
}
.popoverAudio > div:last-child {
    border-bottom: none;
}
.popoverAudio h5 {
    color: var(--white);
    transition: ease-in-out 4ms;

}

.popoverAudio h5:hover , .active h5{
    opacity: 1;
    transition: ease-in-out 4ms;
}
.popoverAudio .edit > div {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.popoverAudio .edit > div > svg{
    margin-left: 0;
}
.profile_image, .popoverAudio > div > img {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: var(--border-disabled);

}

.text > span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Add this line */
}

.box > header > svg {
    cursor: pointer;
}

.box .arrow {
    border: 1px solid var(--border-disabled);
    background: var(--bgColor-disabled);
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: ease-in-out .3s;
}

.box .arrow:hover {
    background: var(--border-disabled);
}

.box .arrow > svg {
    transform: rotate(180deg);
}

.box .logo {
    margin-top: 2rem;
    background: transparent;
}
.box .logoOpened {
    margin-right: 2rem;

}
