.card {
    position: relative;
    max-width: 280px;
    height: 100%;
    border: none;
    cursor: pointer;
    max-height: 165px;
}

.card .poster {
    width: 280px;
    height: 165px;
    object-fit: cover;
    border-radius: 16px;
}

.card .progress_wrapper {
    position: absolute;
    bottom: .5rem;
    left: 1rem;
    right: 1rem;
    border-radius: 100px;
    background: var(--border-disabled-BG);
    backdrop-filter: blur(10px);
    padding: 8px;

}

.progress_wrapper .progress {
    border-radius: 100px;
}

@media (max-width: 860px) {
    .card .poster {
        max-width: 200px;
        width: 100%;
        max-height: 160px;
        height: fit-content;
    }

    .root {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1rem;
        align-items: start;
    }

    .root_right, .root_right > div {
        gap: .5rem;
    }

    .root_right h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

    .root_right h5 {
        font-size: 12px;
        line-height: 17px;
    }
}

@media (max-width: 550px) {
    .root {
        grid-template-columns: 1fr 1fr;
    }
}
