.content {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
}

.content_list {
    display: grid;
    gap: 1.5rem;
}


.content_list > div > span {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
}

.content_list > div > button {
    justify-content: start;
    height: 60px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.avatars {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.avatars > img {
    width: 160px;
    height: 160px;
    opacity: .7;
    border-radius: 24px;
    cursor: pointer;
}

.avatars > img:hover, .avatars .active {
    opacity: 1;
    box-shadow: 0 0 0 4px var(--white);

}

.root {
    width: 100%;
    display: flex;
    gap: 4rem;
    justify-content: center;

}

.root .title {
    font-size: 24px;
    line-height: 32px;
    opacity: .5;
}

.root .list {
    width: 100%;
    max-width: 420px;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns with equal width */
}

.root .list > div:global(.Mui-selected) {
    background: var(--bgColor-disabled);
    border-radius: 24px;
    border: 1px solid var(--border-disabled);
}

.root .list > div:global(.MuiButtonBase-root) {
    padding: 20px 24px;
}

.root .list > div:global( .MuiListItemButton-root) {
    padding: 20px 24px !important;
    margin: 0;
    gap: .5rem;
    opacity: 1;
}

.root .list .name > span {
    font-size: 20px;
    line-height: 28px;
}

.root .box {
    max-width: 650px;
    padding: 1rem;
    background: #FFFFFF14;
    border: 1px solid var(--border-disabled);
    border-radius: 24px;
}

.root .active {
    background: var(--brand-3);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

}

.subtitle_input {
    font-size: 14px;
    line-height: 1.2rem;
    color: #FFBF00;
    margin-top: .5rem;
}

/*@media (max-width: 1260px) {*/
/*    .content_list > div {*/
/*        grid-template-columns: 1fr;*/
/*    }*/
/*}*/

@media (max-width: 860px) {
    .root .list {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .root .box {
        background: transparent;
        border: none;
    }

    .root .active {
        background: var(--brand-3);
    }

    .root .box h2 {
        font-size: 18px;
    }

    .root .box h4 {
        margin-top: 1.5rem;
        font-size: 1rem;
        line-height: 22px;
        color: #a7a7a7;
    }

    .content_list {
        gap: 1rem;
        margin: 0 .85rem;
    }

    .content {
        gap: .85rem;
        justify-content: center;
    }

    .content > h2 {
        font-size: 16px;
        line-height: 24px;
    }


    .avatars > img {
        width: 100px;
        height: 100px;
    }

    .root .list .name > span {
        font-size: 16px;
        line-height: 22px;
    }

}
