.content {
    padding: var(--layout-padding);
    position: relative;
}

.content .title {
    font-size: 24px;
    line-height: 32px;
    opacity: .5;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-items: center;
    text-align: center;
    gap: 1.5rem;
}

.content_inner {
    display: flex;
    gap: 1rem; /* space between cards */
    flex-wrap: wrap;
    justify-content: flex-start; /* optional, centers the items */
    padding: 4rem 0;
}

.card {
    position: relative;
    flex: 1 1 160px; /* makes the cards grow/shrink, with a minimum width of 160px */
    max-width: 180px; /* restricts maximum width */
    height: auto; /* adjust height automatically based on content */
    border: none;
    cursor: pointer;
}

@media (max-width: 860px) {
    .content {
        display: none;
    }
    .content_inner {
        justify-content: center;
    }
    .card {
        max-width: 116px;
    }
    .root {
        margin: 6rem 1.5rem;
    }

    .search > div {
        max-width: 100%;
    }

}
