.box {
    max-width: 850px;
    display: grid;
    gap: 2rem;
    position: relative;
    @media (min-width: 860px) {
        margin-top: 4.5rem;
    }
}

.box > h2 {
    font-size: 48px;
    line-height: 56px;
}

.chips {
    gap: .5rem;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.box > h5 {
    font-size: 16px;
    line-height: 24px;
    max-width: 650px;
}

.box .rating {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 1rem;
}

@media (max-width: 860px) {
    .box > h2 {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
    }

    .box > h5 {
        font-size: 12px;
        line-height: 17px;
        text-align: center;
    }

    .box .chips {
        justify-content: center;
        margin-top: 0;
    }

    .box_details .chips {
        justify-content: flex-start;
    }

    .box button {
        height: 46px;
    }

    .box .rating > svg {
        width: 12px;
        height: 12px;

    }

    .box .rating {
        font-size: 12px;
        font-weight: 800;

    }

    .box_details {
        margin-top: 1rem;
        gap: 1rem;
        text-align: start;
    }

    .box_details > h2, .box_details > h5 {
        text-align: start;
    }
}
