.card {
    position: relative;
    max-width: 280px;
    height: 100%;
    border: none;
    cursor: pointer;
}

.card .poster {
    width: 280px;
    height: 165px;
    object-fit: cover;
    border-radius: 16px;
    animation: fadeIn .5s ease-in-out forwards;
}

.card .hidden {
    display: none;
    animation: fadeIn .5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        opacity: 1; /* End with opacity 1 */
    }
}

.card .progress_wrapper {
    animation: fadeIn .5s ease-in-out forwards;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;

}

.progress_wrapper {
    background: rgba(255, 255, 255, 0.16);

    padding: 6px 8px;
    border: 1px solid var(--border);
    border-radius: 100px;
    backdrop-filter: blur(10px); /* Add blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
}

.card .progress {
    margin: 0 .5rem;
}

.tab {
    border: none;
    padding: 0;
}

.type {
    position: absolute;
    top: .5rem;
    left: .5rem;
    background: var(--base-night);
    border: 1px solid var(--disabled-text);
    border-radius: 100px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    color: var(--white);
    text-transform: lowercase;
}

.type::first-letter {
    text-transform: uppercase;
}

.content {
    padding: 16px;
    cursor: pointer;
    backdrop-filter: blur(10px); /* Add blur effect */

}

.content h5 {
    color: var(--white);
    font-size: 28px;
    line-height: 36px;

}

.content .rating {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    margin: 0 .5rem 0 0;
    color: var(--white);
}

.content .controls {
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin-top: 1rem;
    border-top: 1px solid var(--bgColor-disabled);

}

.content .controlsLeft {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.content .controlsLeft > button, .content .controlsRight > button {
    border: 1px solid var(--border-disabled);
    border-radius: 16px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.08);
    cursor: pointer;
}

@media (max-width: 860px) {
    .card {
        max-width: 100%;
    }
    .card .poster {
        width: 100%;
    }
}


