.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 570px;
    padding: 40px;
    height: max-content;
    max-height: 80vh;
    overflow: auto;
    border-radius: 16px;
    border: 1px solid var(--border-disabled);
    background: #3a3a3a;
    transform: translate(-50%, -50%);

}

.modal .modalClose {
    position: absolute;
    right: 1rem;
    cursor: pointer;
}

@media (max-width: 860px) {
    .modal {
        width: calc(100% - 50px);
        margin: 0 auto;
        padding: 1rem;
    }
    .modal h1 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;

    }
}
