.snackbar {
    animation: snack .5s;
    background: var(--bgColor);
    border: 1px solid var(--border-disabled);
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 16px 20px -8px rgba(17, 12, 34, 0.10);
    max-width: 360px;
}


.snackbar .snackbarContent {
    display: grid;
    grid-template-columns: 0fr 1fr 0fr;
    width: 100%;
    gap: 10px;
}

.snackbar .snackbarContent .alarm svg {
    border-radius: 50%;
    /*border: 1px solid var(--surface-4);*/
    padding: 4px;
}

.snackbar .snackbarContent .snackbarRight {
    display: grid;
    justify-items: start;
    gap: 4px;
}

.snackbar .snackbarContent .snackbarRight h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.snackbar .snackbarContent .snackbarRight h4 {
    /*color: var(--text-medium);*/
    line-height: 24px;
    max-width: 250px;
    font-size: 16px;
}

.snackbar .snackbarContent > svg:last-child {
    cursor: pointer;
}
