.box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.box > div:first-child {
    flex: 1;
    max-height: 50%; /* Control the height of the image container */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Important for the pseudo-element positioning */
}

.box > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    position: relative; /* Ensure the image sits below the blur effect */
}

.box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%; /* Adjust this for how much of the left side you want blurred */
    background: linear-gradient(90deg, rgba(20, 20, 20, 60.7) 0%, rgba(20, 20, 20, 0) 100%) ;
    pointer-events: none; /* Allow interaction with content under the blur */
    z-index: 1;
}

.content {
    padding: 1rem;
    z-index: 2;
}
