.wrapper {
    padding: var(--layout-padding);
}

.title {
    font-size: 24px;
    line-height: 32px;
    opacity: 0.5;
    margin-bottom: 2rem;
}

.content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 1rem;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 860px) {
    .title {
        display: none;

    }
    .contentMobile {
        display: block;
        padding-top: 2.5rem;
        overflow: hidden;
    }
    .contentMobileSelected {
        padding-top: 0;
    }
}
