.content {
    padding: var(--layout-padding);
}

.content {
    height: 85vh;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    animation: fadeIn .8s ease-in-out forwards;
    overflow: hidden; /* Ensure children don't overflow */
}

@media (min-width: 860px) {

    .content::before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: 5%; /* Cover 20% of the width */
        height: 100%;
        background: linear-gradient(90deg, #141414 60.67%, rgba(20, 20, 20, 0) 100%);
        filter: blur(1px); /* Apply the blur effect */
        pointer-events: none; /* Allow clicks to pass through */
    }
}

.content::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%; /* Adjust height for the amount of blur */
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%);
    filter: blur(0); /* Increase blur for the bottom */
    pointer-events: none; /* Ensure it doesn't interfere with interactions */
}


.content .title {
    font-size: 24px;
    line-height: 32px;
    opacity: .5;
}


.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 860px) {
    .image_mobile {
        height: 280px;
        width: 100%;
        object-fit: cover;
    }

    .title {
        display: none;
    }

    .content {
        height: 100%;
        min-height: 60vh;
    }
}
