.card {
    position: relative;
    max-width: 180px;
    min-width: 160px;
    height: 100%;
    border: none;
    cursor: pointer;
}

.chipLive {
    width: fit-content;
    position: absolute;
    top: .5rem;
    left: .5rem;
    animation: fadeIn .5s ease-in-out forwards;

}

.chipLiveProgram {
    position: absolute;
    top: .5rem;
    right: .5rem;
    left: auto;
    z-index: 10;
}

.card .new {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: var(--brand-3);
    padding: 6px 12px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.cardProgram {
    max-width: 280px;
    position: relative;
}

.poster {
    width: 180px;
    height: 260px;
    object-fit: cover;
    border-radius: 16px;
    animation: fadeIn .5s ease-in-out forwards;
}

.card .posterProgram {
    object-fit: cover;
    height: 160px;
    width: 280px;
    position: relative;
}

.hidden {
    display: none;
    animation: fadeIn .5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0; /* Start with opacity 0 */
    }
    to {
        opacity: 1; /* End with opacity 1 */
    }
}

.posterProgramTitle {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    position: absolute;
    top: 1rem;
    left: 1rem;

}

.posterProgramSubtitle {
    display: grid;
    justify-items: start;
    gap: .25rem;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
}

.posterProgramSubtitle > h4 {
    font-weight: 800;
}

.tab {
    border: none;
    padding: 0;
}
.popover_card {
    width: 430px;
}
.type {
    position: absolute;
    top: .5rem;
    left: .5rem;
    background: var(--base-night);
    border: 1px solid var(--disabled-text);
    border-radius: 100px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    color: var(--white);
    text-transform: lowercase;
    z-index: 100;
}

.type::first-letter {
    text-transform: uppercase;
}

.cardImage {
    animation: fadeIn .5s ease-in-out forwards;

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.content {
    padding: 16px;
    cursor: pointer;
}

.content h5 {
    color: var(--white);
    font-size: 28px;
    line-height: 36px;

}

.content .rating {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    margin: 0 .5rem 0 0;
    color: var(--white);
}

.content .controls {
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin-top: 1rem;
    border-top: 1px solid var(--bgColor-disabled);

}

.content .controlsLeft {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.content .controlsLeft > button, .content .controlsRight > button {
    border: 1px solid var(--border-disabled);
    border-radius: 16px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.08);
    cursor: pointer;
    transition: ease-in-out .3s

}

.content .controlsLeft > button:hover, .content .controlsRight > button:hover {
    background: var(--border-disabled);
    transition: ease-in-out .3s;
}

.tabs::-webkit-scrollbar {
    display: none;
}

.tabs {
    position: relative;
}

.tabs:global(.MuiTabs-root .MuiTabs-scrollButtons) {
    position: absolute;
    z-index: 100;
}

.tabs:global(.MuiTabs-root .MuiTabs-scrollButtons:last-child) {
    right: .5rem;
}

.tabs:global(.MuiTabs-root .MuiTabs-scrollButtons:first-child) {
    left: .5rem;
}

@media (max-width: 860px) {
    .card {
        min-width: 116px;
        min-height: 164px;
    }
    .popover_card {
        width: 100%;
        min-width: 320px;
    }

    .poster {
        width: 116px;
        height: 164px;
        object-fit: cover;
        border-radius: .5rem;
        animation: fadeIn .5s ease-in-out forwards;
    }
}
