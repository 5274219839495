.root {
    display: grid;
    place-content: start;
    margin: 3rem 1rem;
    width: 100%;
}

.root > h4 {
    color: var(--white);
    font-size: 1.25rem;
}
