
.root {
    margin: 16px 0 24px;
    padding: 16px;
    border-radius: 8px;
    display: grid;
    gap: 1rem;
}

.root .error {
    color: var(--error);
    padding: 0;
    font-size: 12px;
    line-height: 16px;
}
.container{
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
}

.root .character {
    display: grid;
    align-items: center;
    width: 80px;
    height: 100px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 24px;
    border: 1px solid var(--border-disabled);
    color: var(--white);
    box-shadow: none;

}




.root .characterSelected {
    outline: none;
    border: 1px solid var(--white);
    color: var(--white);
}

.root .characterFilled {
    color: var(--white);
}


@media screen and (max-width: 860px) {
    .root {
        margin: 0;
    }
    .root .container {
        gap: 1rem;
    }
    .root .character {
        width: 50px;
        height: 65px;
        border-radius: .5rem;
    }

}
