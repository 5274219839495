
.input {
    display: grid;
    gap: 8px;
    width: 100%;
    max-width: 420px;
    border: none;
}

.input .title:global(.MuiTypography-root ) {
    color: var(--disabled-text);
    font-size: 12px;
    font-weight: 600;
}

.inputWrapper{
    position: relative;
}
