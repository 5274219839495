.wrapper {
    position: relative;
    height: 100%;
}

.wrapperControls {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    z-index: 10;
    padding: 0 2rem;
    display: block;
    transition: ease-in-out .2s;
}

.wrapperControlsVisible {
    display: block;
    transition: ease-in-out .2s;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    display: flex;
    gap: 1rem;
    align-items: center;
    position: absolute;
    top: 4rem;
    width: 100%;
    padding: 0 2rem;
    transition: ease-in-out 1s;
}

.title > button {
    transform: rotate(180deg);
    border: 1px solid var(--border-disabled);
    background: var(--bgColor-disabled);
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: ease-in-out .3s;
}

.title > div:hover {
    background: var(--border-disabled);
}

.title > div > svg {
    transform: rotate(180deg);
}

.progress_wrapper {
    background: var(--border-disabled-BG);
    padding: 4px;
    border: 1px solid var(--border);
    border-radius: 100px;
    backdrop-filter: blur(10px); /* Add blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
}

.progress_wrapper .progress {
    cursor: pointer;
}

.tvList {
    position: absolute;
    left: 2rem;
    top: 6rem;
    height: 100%;
    transition: ease-in-out 1s;
}

.serial_episodesWrapper {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 40px;
    align-items: flex-start;
    padding: 4rem 1rem 1rem;
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s ease;
    animation: slideInFromLeft 0.5s ease forwards;


}

.serial_episodesWrapper::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%; /* Cover 20% of the width */
    height: 100%;
    background: linear-gradient(270deg, #141414 70%, rgba(20, 20, 20, 0) 100%);
    filter: blur(10); /* Apply the blur effect */
    pointer-events: none; /* Allow clicks to pass through */
}

.serial_episodesWrapper.animateIn {
    z-index: 10;
    height: 100%;
    animation: slideInFromLeft 0.5s ease forwards;
}

.serial_episodesWrapper.animateOut {
    height: 100%;
    animation: slideOutToLeft 0.5s ease forwards;
}

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(100%);
    }
}

.serial_episodesWrapper > button {
    width: 52px;
    height: 52px;
}

.episodesWrapper {
    height: 100%;
    overflow: auto;
}

.serial_episodes {
    display: grid;
    overflow-y: scroll;
    height: 100%;
    opacity: 1;
    transition: ease-in-out 1s;
    z-index: 0;
    padding-bottom: 2rem;
}

.serial_title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin: .5rem 0 2rem;
}

.serial_episodes ::-webkit-scrollbar {
    display: none;
}

.wrapper:global(.MuiButtonBase-root .MuiTab-root) {
    border: none !important;
    opacity: 1;
}

/*.serial_episodes:hover {*/
/*    transition: ease-in-out 1s;*/
/*    bottom: 1rem;*/
/*    z-index: 20;*/
/*    opacity: 1;*/
/*}*/


.modalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 101vh;
    background: black;
    backdrop-filter: blur(10px);
}

.modalContent video {
    object-fit: contain;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}


.popoverAudio {
    max-width: 530px;
    width: 100%;
}

.popoverAudio .popoverAudioForm {
    display: grid;
    gap: 40px;
    width: 430px;
    padding: 40px;
}

.popoverAudio .popoverAudioForm h2 {
    color: var(--white)
}

@media (max-width: 860px) {
    .title {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .title > button {
            min-width: fit-content;
            min-height: fit-content;
            padding: .5rem;
            border-radius: .25rem;
            height: 30px;
    }

    .wrapperControls > div > h3 , .wrapperControls > div > h4{
        font-size: 14px;
        line-height: 20px;
    }
    .controls_mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
    .controls_mobile button {
        height: 36px;
    }
    .serial_episodesWrapper {
        display: grid;
        background: var(--bgColor);
        width: 100%;
    }

    .serial_episodesWrapper > button {
        transform: rotate(180deg);
    }
    .serial_episodesWrapper::before {
        display: none;
    }
}
