html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}


ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
}

* ::-webkit-scrollbar {
    background: transparent;
    width: 0;
    height: 0;
}

*::-webkit-scrollbar-thumb {
    background: transparent;
    width: 0;
    height: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

*, *::before, *::after {
    box-sizing: border-box;
}


html, body, #root {
    height: 100%;
}

.disable-hover {
    pointer-events: none;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: var(--white);
    overscroll-behavior: contain;
    direction: ltr;
    background-color: var(--bgColor);
    line-height: 1;
    overflow-x: hidden;
}

input:focus,
select:focus,
div:focus {
    outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

textarea {
    resize: none;
    overflow-y: auto;
    border: none
}

u {
    cursor: pointer;
}

* {
    touch-action: pan-x pan-y;
    user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-transition-delay: 9999s;
    -webkit-transition-property: background-color, color;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

:root {
    --bgColor: #141414;
    --white: #fff;
    --grey-text: rgba(255, 255, 255, 0.72);
    --disabled-text: rgba(255, 255, 255, 0.22);
    --brand-3: #CD0000;
    --brand-4: #830000;
    --brand-disabled: rgba(205, 0, 0, 0.5);
    --bgColor-disabled: rgba(255, 255, 255, 0.04);
    --border: rgba(255, 255, 255, 0.22);
    --border-disabled: rgba(255, 255, 255, 0.16);
    --border-disabled-BG: rgba(255, 255, 255, 0.08);
    --base-night: rgba(20, 20, 20, 1);
    --error: #FF0402;
    --header-height: 68px;
    @media (min-width: 860px) {
        --layout-padding: 48px 24px;
    }
    @media (max-width: 860px) {
        --layout-padding: 90px 24px;
    }

    --orange: rgba(255, 107, 0, 1);

}

body .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    background-color: var(--bgColor-disabled);
}


body .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    outline: none;
}

.MuiTabScrollButton-root.Mui-disabled {
    display: none;
}
