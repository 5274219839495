@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-ExtraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakarta/PlusJakartaSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


