.root {
    height: calc(100vh - 1rem);
    padding: 12px 12px 48px;
    animation: fadeIn .5s ease-in-out forwards;

}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.root .box {
    width: 100%;
    padding-bottom: 2rem;
    overflow-x: scroll;
}

.root .row {
    justify-content: space-between;
    align-items: center;
    margin: 3rem 2rem 1rem 0;
}

.root .row > h3 {
    font-size: 24px;
    line-height: 32px;
    color: var(--white);

}
.root .row > h4 {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 18px;
    line-height: 26px;
    color: var(--white);
    cursor: pointer;
}
.loader {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 860px) {
    .root .row {
        margin: 1rem 0 1rem .5rem;
    }
    .root .row > h3 {
        font-size: 16px;
        line-height: 18px;

    }
}
