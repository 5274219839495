.root{
    display: grid;
    justify-content: center;
    gap: 24px   ;
    justify-items: center;
}
.root > button {
    margin-top: 16px;
}
@media screen and (max-width: 860px) {
.root {
    gap: 1rem;
}
.root > h2 {
    font-size: 24px;
    text-align: center;
    line-height: 28px;
}
    .root > h4 {
        font-size: 1rem;
    }
}
