.wrapper {
    display: grid;
    gap: 0.5rem;
    max-height: calc(100vh - 230px);
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 0.5rem; /* Optional: Add some padding to avoid content getting cut off by the scrollbar */
    animation: fadeIn 0.6s ease-in-out;
}

.wrapperPlayer {
    max-height: 100%
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.wrapper::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}


.wrapper:hover::-webkit-scrollbar-thumb {
    background-color: var(--border-disabled); /* No color change on hover */
    border-radius: 16px;
}

.card {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 4fr;
    /*max-width: 460px;*/
    padding: 16px;
    border-radius: 16px;
    /*height: 100px;*/
    cursor: pointer;
    width: 100%;
    border: 1px solid transparent;
    transition: background-color .3s ease-in-out, border .3s ease-in-out; /* Only transition background and border */
}


.card > img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 8px;
}

.cardBlur {
    background: var(--bgColor-disabled);
    backdrop-filter: blur(5px);
    transition: ease-in-out .3s;
}

.wrapper .activeBlur, .wrapper .cardBlur:hover {
    background: var(--disabled-text);
    backdrop-filter: blur(5px); /* Increase blur on hover/active */
    border: 2px solid var(--white);
    z-index: 100000;
    transition: ease-in-out .3s;
}

.card > div {
    display: grid;
    gap: .5rem;

}

.card > div > h2, .card > div > h4 {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    max-width: 95%;
}

.card > div > h4 {
    opacity: 64%;
}

.card:hover, .active {
    background: var(--border-disabled-BG);
    border-color: var(--border-disabled)
}
@media (max-width: 860px) {
    .wrapper{
        padding: 0;
    }
}
