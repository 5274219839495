.root {
    padding: var(--layout-padding);
    min-height: 100%;
}

.root .title {
    font-size: 24px;
    line-height: 32px;
    opacity: .5;
}

.root .list {
    width: 100%;
    max-width: 420px;
    height: fit-content;
    display: grid;
    gap: 5px;
}

.root .list > div:global(.Mui-selected) {
    background: var(--bgColor-disabled);
    border-radius: 24px;
    border: 1px solid var(--border-disabled);

}

.root .list > div:global(.MuiButtonBase-root) {
    padding: 20px 24px;
}

.root .list > div:global( .MuiListItemButton-root) {
    padding: 20px 24px !important;
    margin: 0;
    gap: .5rem;
    opacity: 1;
}

.root .listSettings > div:last-child:global( .MuiListItemButton-root) {
    margin-top: 40%;
}

.root .list .name > span {
    font-size: 20px;
    line-height: 28px;
}

.leftBlock {
    display: grid;
    gap: 3rem;
    width: 100%;
}

.leftBlock .profileCardKids {
    margin-top: 30%;
}

.leftBlock .profileCard {
    display: grid;
    justify-items: center;
    gap: 2rem;
    padding: 36px;
    background: var(--bgColor-disabled);
    border-radius: 24px;
    max-height: 350px;
    height: 100%;
    max-width: 420px;
    width: 100%;
}


.leftBlock .profileCard .imageContainer > img {
    width: 160px;
    height: 160px;
    border-radius: 24px;
}

.leftBlock .profileCard .kids {
    width: 160px;
    height: 160px;
    border-radius: 1rem;
    transition: box-shadow 0.2s ease-in-out;
    background: linear-gradient(329.12deg, #FF0303 0%, #FFBF00 55.25%, #30FFF3 99.71%);
    border: none;
    position: relative;
}

.leftBlock .profileCard .kids > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
}

.leftBlock .profileCard .card > h2 {
    font-size: 28px;
    line-height: 36px;
    max-width: 320px;
    text-align: center;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Add this line */
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.edit_icon {
    display: none;
}

@media (max-width: 860px) {
    .imageContainer {
        position: relative;
    }

    .edit_icon {
        display: block;
        position: absolute;
        bottom: .5rem;
        right: .5rem;
    }

    .root > div {
        align-items: center;
    }

    .root .title {
        font-size: 18px;
        line-height: 24px;
    }

    .root .list {
        gap: 8px;
    }

    .root .list .name > span {
        font-size: 16px;
        line-height: 22px;
    }

    .leftBlock {
        display: flex;
        flex-direction: column-reverse;
        gap: 1.5rem;
        margin-top: 1rem;
    }


    .leftBlock .profileCard {
        padding: 16px;
        max-height: 300px;
        background: transparent;
        max-width: 100%;
    }

    .leftBlock .profileCard > .card {
        display: none;
    }

    .leftBlock .profileCard .imageContainer > img {
        width: 120px;
        height: 120px;
        border-radius: .5rem;
    }

    .leftBlock .profileCard .kids {
        width: 120px;
        height: 120px;
    }

    .leftBlock .profileCard > .card > h2 {
        font-size: 20px;
        line-height: 28px;
    }
}
