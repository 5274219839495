.root {
    padding: var(--layout-padding);
}

.root .title {
    font-size: 24px;
    line-height: 32px;
    opacity: .5;
}


.root .list {
    display: grid;
    height: max-content;
    gap: 5px;
}

.loader {
    height: 50vh;
    display: grid;
    justify-items: center;
    align-items: center;
}

.root .list > div:global(.Mui-selected) {
    background: var(--bgColor-disabled);
    border-radius: 24px;
    border: 1px solid var(--border-disabled);
}

.root .list > div:global(.MuiButtonBase-root) {
    padding: 20px 24px;
}

.root .list > div:global( .MuiListItemButton-root) {
    padding: 20px 24px !important;
    margin: 0;
    gap: .5rem;
    width: 280px;
    opacity: 1;
}

.root .list .name > span {
    font-size: 20px;
    line-height: 28px;
}

@media (max-width: 860px) {
    .root .list > div:global(.MuiButtonBase-root) {
        background: var(--bgColor-disabled);
        border-radius: 24px;
    }

    .root .list > div:global(.Mui-selected) {
        border: none;
    }

    .root .list {
        width: 100%;
    }

    .root .list > div:global( .MuiListItemButton-root) {
        width: 100%;
    }

    .fullCard {
        width: 100%;
    }

    .root .list .name > span {
        font-size: 1rem;
        line-height: 24px;
    }
}
