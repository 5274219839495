.root {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100dvh;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.aside {
    position: fixed; /* Keep the aside fixed */
    top: 0;
    left: 0;
    width: 150px; /* Set aside width to 150px */
    height: 100%;
    display: grid;
    justify-items: center;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(20, 20, 20, 0.971) 45%);
    animation: fadeIn 0.5s ease-in-out forwards;
    z-index: 1000; /* Ensure the aside stays on top of other elements */
}


.main {
    margin-left: 120px; /* Adjust the main content to start after the aside */
    width: calc(100% - 120px); /* Ensure the main content fills the remaining space */
    position: relative;
    flex: 1;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; /* This makes the background image fixed */
    background-position: center;
    animation: fadeIn .8s ease-in-out forwards;
}

.mainWithoutMenu {
    height: auto;
}

@media (max-width: 860px) {
    .root {
        min-height: auto;
    }
    .root .aside {
        display: flex;
        height: 100px;
        width: 100%;
    }

    .root .main {
        width: 100%;
        margin-left: 0;
    }

    .aside_mobile {
        top: 0;
        z-index: 1000;
        width: 100%;
        /*height: 100px;*/
        display: flex;
        align-items: center;
        position: relative;
    }

    .aside_mobile > header {
        width: 100%;
        max-height: 100px;
        padding: 2.5rem 1.5rem;
        background: transparent;
        height: fit-content;
        position: fixed;
        top: 0;
    }
}


@media (max-width: 768px) {
    .aside {
        width: 150px; /* Keep the aside width fixed */
    }

    .main {
        margin-left: 150px; /* Adjust the margin for the main content */
        width: calc(100% - 150px); /* Adjust main content width */
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
