.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    height: 60px;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 24px;
    font-size: 14px;
    line-height: 16px;
    transition: background-color 0.4s, border-color 0.3s, color 0.3s;
    color: #fff;
    user-select: none;
}

.primary {
    background-color: var(--brand-3);
}

.primary:hover {
    background-color: var(--brand-4);
}

.fullWidth {
    width: 100%;
}

.disabled {
    color: var(--disabled-text);
    background: var(--brand-disabled);
    box-shadow: none;
}

.disabled:hover {
    box-shadow: none;
}

.loader {
    border: 4px solid var(--brand-3);
    border-left-color: var(--grey-text);
    border-right-color: var(--grey-text);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
