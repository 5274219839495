.wrapper {
    margin: .5rem;
    position: relative;
}

.card {
    position: relative;
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 40px;
    height: 100%;
    border: none;
    cursor: pointer;
}

.card .poster {
    width: 280px;
    height: 164px;
    object-fit: cover;
    border-radius: 16px;

}

.card .poster:hover, .card .poster_active {
    border: 4px solid var(--white);
    transition: ease-in-out .3s;
}

/*.card .episode_title {*/
/*    position: absolute;*/
/*    left: 1rem;*/
/*    top: .5rem;*/
/*}*/

.card .progress_wrapper {
    display: grid;
    gap: .5rem;
    max-width: 230px;
}

.progress_wrapper > h4 {
    margin-top: 1rem;
}

.episode_count, .progress_wrapper > h4 {
    font-size: 16px;
    line-height: 24px;
    color: var(--grey-text);
    font-weight: 400;
}

.episode_title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.card .progress_wrapper > h2 {
    width: 100%;
    display: inline-block;
    overflow-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 860px) {
    .wrapper {
        margin: 1rem .5rem;
    }
    .card{
        grid-template-columns: 1fr;
        justify-content: center;
        gap: .5rem;
    }
    .card > div:first-child > img{
        width: 100%;
    }
    .mobile_episodeInfo {
        position: absolute;
        top: 1rem;
        left: .5rem;
    }
    .mobile_episodeInfo .episode_count {
        font-size: 14px;
    }
    .mobile_episodeInfo .episode_title {
        font-size: 16px;
    }
    .card .progress_wrapper {
        display: none;
    }
}
